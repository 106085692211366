import React, { Component } from "react";
import Slider from "react-slick";
import "./journalcarusel.css"
export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "Carusjournal",
      centerMode: true,
      infinite: true,
      centerPadding: "100px",
      slidesToShow: 5,
      speed: 3000,
      autoplay:true,
    };
    return (
<div class="swiffy-slider slider-item-reveal slider-nav-round slider-item-ratio slider-item-ratio-21x9" id="slider1">
    <ul class="slider-container">
        <li><img src="../assets/img/photos/img4.webp" loading="lazy" alt="..."/></li>
        <li><img src="../assets/img/photos/img5.webp" loading="lazy" alt="..."/></li>
        <li><img src="../assets/img/photos/img6.webp" loading="lazy" alt="..."/></li>
        <li><img src="../assets/img/photos/img7.webp" loading="lazy" alt="..."/></li>
    </ul>

    <button type="button" class="slider-nav" aria-label="Go left" fdprocessedid="wp8dfq"></button>
    <button type="button" class="slider-nav slider-nav-next" aria-label="Go left" fdprocessedid="ipsvhw"></button>

    <div class="slider-indicators slider-indicators-square d-none d-md-flex">
        <button class="active" aria-label="Go to slide" fdprocessedid="qi2de"></button>
        <button aria-label="Go to slide" fdprocessedid="k07tt" class=""></button>
        <button aria-label="Go to slide" fdprocessedid="yt8rff" class=""></button>
        <button aria-label="Go to slide" fdprocessedid="o3h65" class=""></button>
    </div>

    <div class="slider-indicators slider-indicators-sm slider-indicators-dark slider-indicators-round d-md-none slider-indicators-highlight">
        <button class="active" aria-label="Go to slide"></button>
        <button aria-label="Go to slide" class=""></button>
        <button aria-label="Go to slide" class=""></button>
        <button aria-label="Go to slide" class=""></button>
    </div>
</div>    );
  }
}