import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedValue: 'uz',
};

const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    setSelectedValue: (state, action) => {
      state.selectedValue = action.payload;
    },
  },
});

export const { setSelectedValue } = selectionSlice.actions;
export default selectionSlice.reducer;