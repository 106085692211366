import { Footer } from 'antd/es/layout/layout'
import React from 'react'
import { Navbar } from 'react-bootstrap'
import "./read.css"

function AllJournal() {
  return (
    <div>
        <Navbar/>
        <section>
            <div className="container Pagepadding">
                <div className="row grid gap-x-4">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className=' p-2'>
                             <img className='alljournalimg' src="./images/ReadJournal/2018-1.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className='p-2'>
                           <img className='alljournalimg' src="./images/ReadJournal/2019-2.jpg" alt="" />  
                        </div>
                       
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className='p-2'>
                            <img className='alljournalimg' src="./images/ReadJournal/2020-1jpg.jpg" alt="" /> 
                        </div>
                       
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className='p-2'>
                            <img className='alljournalimg' src="./images/ReadJournal/g2021-5.jpg" alt="" /> 
                        </div>
                       
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default AllJournal