import React, { useEffect, useState } from 'react'
import axios from 'axios';
import "./banner.css"
import { Link } from "react-router-dom"
//Lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
//redux
import { useSelector } from 'react-redux';
//import json
import dataUZ from "./banner_uz.json"
import dataK from "./banner_k.json"

function Banner() {
  const selectedValue = useSelector(state => state.selection.selectedValue);
  const [data, setdata] = useState([])

  useEffect(() => {
    axios.get(process.env.REACT_APP_API + `/${selectedValue}/api/banner`)
      .then((res) => {
        setdata(res.data.at(-1))
      })
  }, [])



  return (
    <>
      <div className='banber__full01'> <div className='container banner__full'>
        <div className="row">
          <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 position-relative'>
            <div className="banner__01">
              <a href={data.link_teatr}>
                <LazyLoadImage
                  className='img__banner'
                  alt="banner"
                  effect="blur"
                  src={data.img_teatr} />
              </a>

            </div>
            <div className='btn_read'> <a href={data.link_teatr}><button className='btn btn-danger'>Teatr jurnalini o‘qish</button></a> </div>

          </div>
          <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
            <div className='banner__text_main'>
              <div className='mt-5 mb-5'>
                <p className='text-center me-5'>{selectedValue === 'uz' ? dataUZ.banner_text1 : dataK.banner_text1}</p>
                <div className=' banner__text'>
                  <p>{selectedValue === 'uz' ? dataUZ.banner_text2 : dataK.banner_text2}</p>
                </div>
                <p>{selectedValue === 'uz' ? dataUZ.banner_text3 : dataK.banner_text3}</p>
                <Link to="/contact" className='link__obuna'><button className='main__btn'>{selectedValue === 'uz' ? dataUZ.banner_text4 : dataK.banner_text4}</button></Link>
                <a href='https://t.me//Gule_akbarovna' className='link__obuna'><button className='main__btn02 mt-2'>{selectedValue === 'uz' ? dataUZ.banner_text5 : dataK.banner_text5}</button></a>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
            <div className="banner__02">
              <a href={data.link_guliston}>
                <LazyLoadImage
                  className='img__banner'
                  alt="banner"
                  effect="blur"
                  src={data.img_guliston} />
              </a>

            </div>
            <div className='btn_read'> <a href={data.link_guliston}><button className='btn btn-danger'>Guliston jurnalini o‘qish</button></a> </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default Banner