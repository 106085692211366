import React from 'react'
import { useState,useEffect } from 'react'
import NewsCarus from '../NewsCarusel/NewsCarus'
import NewCarusMobile from "../NewsCarusel/NewCarusMobile"
import axios from 'axios'
//redux
import { useSelector } from 'react-redux';
//import Css
import "./allCarus.css"

function AllCarus() {
    const [dataAll,setdataAll] = useState([])
    const selectedValue = useSelector(state => state.selection.selectedValue);
    useEffect(() => {
        axios.get(process.env.REACT_APP_API + `/${selectedValue}/api/`)
        .then((res) => {
            setdataAll(res.data)
        })

    }, [selectedValue])
    // console.log(dataAll)
    {
        var YangilikFilter = dataAll.filter(news => { return (news.category.name === "Yangiliklar") });
        var ArxivFilter = dataAll.filter(news => { return (news.category.name === "Arxiv") });
        var JurnalsahifalaridaFilter = dataAll.filter(news => { return (news.category.name === "Jurnaldan") });
        var MaqolalarFilter = dataAll.filter(news => { return (news.category.name === "Maqolalar") });
      }
  return (
    <>
    <div className='allCarusDekstop'>
        <NewsCarus data={YangilikFilter} title={(selectedValue == "uz"?"Yangiliklar":"Янгиликлар")}/>
        <NewsCarus data={MaqolalarFilter} title={(selectedValue) == "uz"?"Maqolalar":"Мақолалар"}/>
        <NewsCarus data={JurnalsahifalaridaFilter} title={(selectedValue == "uz"?"Jurnaldan":"Журналдан")}/>
        <NewsCarus data={ArxivFilter} title={(selectedValue == "uz"?"Arxiv":"Архив")}/>
    </div>    
    <div className='allCarusMobile'>
        <NewCarusMobile data={dataAll}/>
    </div>
    </>

  )
}

export default AllCarus