import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from 'axios'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./mediaStyle.css"
//redux
import { useSelector } from 'react-redux';

// import required modules
import { Pagination, Navigation, HashNavigation } from "swiper";
import { useEffect } from "react";
//icon

import { AiOutlineClockCircle } from 'react-icons/ai';
import { FcVideoFile } from 'react-icons/fc';


function Medialar() {
  const [sliderdata,setsliderdata] = useState([])
  const selectedValue = useSelector(state => state.selection.selectedValue);
  let datatime = `${sliderdata.created_at}`
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/${selectedValue}/api/video`)
    .then((ress) => {
      setsliderdata(ress.data)
    })

  },[selectedValue])
  return (
    <div>
      <div className="container mx-auto media_title">{selectedValue == "uz" ? "Medialar":"Медиалар"}</div>
          <Swiper
    slidesPerView={1}
    spaceBetween={10}
    hashNavigation={{
      watchState: true,
    }}
    pagination={{
      clickable: true,
    }}
    breakpoints={{
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }}
    navigation={true}
    modules={[Pagination, Navigation, HashNavigation]}
    className="mySwiper container "
  >
    {sliderdata.map((e) => {
      return(
        <div >       
            <SwiperSlide data-hash="slide1" className="slider_option mt-5 mb-5">
              <iframe className=' Youtobevideo'  src={e.video_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
              <p className='mb-3'><AiOutlineClockCircle className='time__icon me-1' />{((e.created_at)).slice(0, 10)} | {((e.created_at)).slice(11, 16)}</p>
              <p className="">{e.video_title.slice(0,42)}...</p>
            </SwiperSlide>
        </div>

      )

    })}

  </Swiper>
    </div>

  )
}

export default Medialar