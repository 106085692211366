import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import "./teatr.css"
import axios from "axios"
import LastNews from '../../CommonComponents/Lastnews/LastNews'
// import json
import teatrjson from "./teatrjson.json"
//import mui
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

//lazy
import { LazyLoadImage } from 'react-lazy-load-image-component';
//redux
import { useSelector } from 'react-redux';
//import json
import dataUZ from "./teatr_uz.json"
import dataK from "./teatr_k.json"

function Teatr() {
    const [array, setarray] = useState([])
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const [datalan, setdatalan] = useState({})
    let data = []
    useEffect(() => {
        axios.get(process.env.REACT_APP_API + "/api")
            .then((res) => {
                setarray(res.data)
            })
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (selectedValue === 'uz') {
            setdatalan({ ...dataUZ })
        } else {
            setdatalan({ ...dataK })
        }
    }, [selectedValue])
    // {
    //     for (let i = 0; i < array.length - 2; i++) {
    //         data.push(array[i])
    //     }
    // }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    return (
        <div>
            <div className='teatr__main __back_img'>
                <div className="back__color"></div>
                <div className='main__text'>
                    <ul className='crumbs'>
                        <li ><Link to="/guliston" className='crumbs-item '>{datalan.title1}</Link></li>
                        <li>/</li>
                        <li ><Link to="/managment" className='crumbs-item'>{datalan.title2}</Link></li>
                        <li>/</li>
                        <li className='crumbs-item main-item'>{datalan.title3}</li>
                    </ul>
                </div>
                {/* <img className='teatr_main_img' src="./images/big_img.jpg" alt="" /> */}
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-sm-12">
                        <h3 className='text-center m-4'>{datalan.main_title}</h3>
                        <div className='InformationPage'>
                            <LazyLoadImage
                                className='InformationPageImg'
                                alt="AboutWeImg"
                                effect="blur"
                                src="./images/teatr02.jpg" />
                        </div>
                        <br />
                        <p>{datalan.text1}</p>
                        <br />
                        <p>{datalan.text2}</p>
                        <ul className='teatr__ul'>
                            <li className='teatr__li'>{datalan.text3}</li>
                            <li className='teatr__li'>{datalan.text4}</li>
                            <li className='teatr__li'>{datalan.text5}</li>
                            <li className='teatr__li'>{datalan.text6}</li>
                        </ul>
                        <br />
                        <p>
                            {datalan.text7}
                        </p>
                        <br />
                        <p>{datalan.text8}</p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12m mt-5">
                        <LastNews data={teatrjson} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Teatr