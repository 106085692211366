import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Link } from "react-router-dom"
import Stack from '@mui/material/Stack';
import NewPaginator from "../../CommonComponents/Paginator/Pagination"
import "./yangiliklar.css"
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import Fullnews from '../../FullNews/Fullnews';
import CircularProgress from '@mui/material/CircularProgress';
//redux
import { useSelector } from 'react-redux';
//import json
import dataTypeUZ from "./Post_type_uz.json"
import dataTypeK from "./Post_type_k.json"

function TabPanel(props, name) {
  const { children, value, index, ...other } = props;
  let location = useLocation()
  //variable

  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);
  const [data, setdata] = useState([])
  const [Yangiliklardata, setYangiliklardata] = useState([])
  const [Maqolalardata, setMaqolalardata] = useState([])
  const [Journaldata, setJournaldata] = useState([])
  const [Arxivdata, setArxivdata] = useState([])
  const [number, setnumber] = useState([])
  const [page, setPage] = useState(1)
  const [countPageY , setCountPageY] = useState(0)
  const [countPageM , setCountPageM] = useState(0)
  const [countPageJ , setCountPageJ] = useState(0)
  const [countPageA , setCountPageA] = useState(0)
  const [type,settype] = useState({})
  let location = useLocation()
    //redux
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const handleChangePage = (event, value) => {
      setPage(value);
    };
  useEffect(() => {
      axios.get(`${process.env.REACT_APP_API}/${selectedValue}/api/pagination?page=${page}&search=Yangiliklar`)
      .then((ress) => {
        setCountPageY(Math.ceil(ress.data.count / 9))
        console.log(Math.ceil(ress.data.count / 9))
        setYangiliklardata(ress.data.results)
      })
      axios.get(`${process.env.REACT_APP_API}/${selectedValue}/api/pagination?page=${page}&search=Maqolalar`)
      .then((ress) => {
        setCountPageM(Math.ceil(ress.data.count / 9)) 
        setMaqolalardata(ress.data.results)
      })
      axios.get(`${process.env.REACT_APP_API}/${selectedValue}/api/pagination?${page}&search=Arxiv`)
      .then((ress) => {
        setCountPageJ(Math.ceil(ress.data.count / 9)) 
        setArxivdata(ress.data.results)
      })
      axios.get(`${process.env.REACT_APP_API}/${selectedValue}/api/pagination?${page}&search=Jurnaldan`)
      .then((ress) => {
        // setCountPage(Math.ceil(ress.data.count / 9)) 
        setCountPageA(Math.ceil(ress.data.count / 9))
        setJournaldata(ress.data.results)
      })
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }, [page,selectedValue])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  return (
    <Box sx={{ width: '100%' }} className="news__select_menu">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      </Box>
      <TabPanel value={location.state.id } index={0}>
        <div className="container new__full mt-4">
          <h2 className='text-center mb-4 categoryTitlename'>{((selectedValue === "uz")?"Yangiliklar":"Янгиликлар")}</h2>
          <div className="row">
            {
              (Yangiliklardata.length>0)?
              Yangiliklardata.map((e, i) => {
                return (
                  <>    
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                       <Link to={`/news/${e.id}`} className='titlenews'> 
                      <div data-aos="zoom-in" className="new__card">
                        <div className='new__img_div me-3'>
                          <img className='new__img' src={e.profile} alt="" />
                        </div>
                        <div>
                          <h6 className="titlenews">{e.title}</h6>
                          <div><button className='new__btn'>{((selectedValue === "uz")?"Yangiliklar":"Янгиликлар")}</button><span className='new__data'>{((e.created_at).toString()).slice(0,10)} | {((e.created_at).toString()).slice(11,16)}</span></div>
                        </div>
                      </div>
                       </Link>
                    </div>
                  </>
                )
              })
              :
              <div className='text-center'>
                < CircularProgress/>
              </div>
            }
          </div>
        </div>
        <Pagination page={page} onChange={handleChangePage}  count={countPageY} color="primary" />
      </TabPanel>
      <TabPanel value={location.state.id } index={1}>
        <div className="container new__full mt-4">
        <h2 className='text-center mb-4 categoryTitlename'>{((selectedValue === "uz")?"Maqolalar":"Мақолалар")}</h2>
          <div className="row">
            {
              (Maqolalardata.length >0)?
             
              Maqolalardata.map((e, i) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                    <Link to={`/news/${e.id}`} state={Maqolalardata[i]} className="titlenews">
                      <div data-aos="zoom-in" className="new__card">
                        <div className='new__img_div me-3'>
                          <img className='new__img' src={e.profile} alt="" />
                        </div>
                        <div>
                          <h6 className="titlenews">{e.title}</h6>
                          <div><button className='new__btn'>{((selectedValue === "uz")?"Maqolalar":"Мақолалар")}</button><span className='new__data'>{((e.created_at).toString()).slice(0,10)} | {((e.created_at).toString()).slice(11,16)}</span></div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </>
                )
              })
              :
              <div className='text-center'>
                 < CircularProgress/>
              </div>
            }
          </div>
        </div>
        <Pagination page={page} onChange={handleChangePage}  count={countPageM} color="primary" />
      </TabPanel>
      <TabPanel value={location.state.id } index={2}>
        <div className="container new__full mt-4">
        <h2 className='text-center mb-4 categoryTitlename'>{((selectedValue === "uz")?"Jurnaldan":"Журналдан")}</h2>
          <div className="row">
            {
              (Maqolalardata.length>0)?
              Journaldata.map((e, i) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                    <Link to={`/news/${e.id}`} state={Journaldata[i]} className="titlenews">
                      <div data-aos="zoom-in" className="new__card">
                        <div className='new__img_div me-3'>
                          <img className='new__img' src={e.profile} alt="" />
                        </div>
                        <div>
                          <h6 className="titlenews">{e.title}</h6>
                          <div><button className='new__btn'>{((selectedValue === "uz")?"Jurnaldan":"Журналдан")}</button><span className='new__data'>{((e.created_at).toString()).slice(0,10)} | {((e.created_at).toString()).slice(11,16)}</span></div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </>
                )
              })
              :
              <div className='text-center'>
                < CircularProgress/>
              </div>
            }
          </div>
        </div>
        <Pagination page={page} onChange={handleChangePage}  count={countPageJ} color="primary" />
      </TabPanel>
      <TabPanel value={location.state.id } index={3}>
        <div className="container new__full mt-4">
        <h2 className='text-center mb-4 categoryTitlename'>{((selectedValue === "uz")?"Arxiv":"Архив")}</h2>
          <div className="row">
            {
              (Maqolalardata.length>0)?
              Arxivdata.map((e, i) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">
                    <Link to={`/news/${e.id}`} state={Arxivdata[i]} className="titlenews">
                      <div data-aos="zoom-in" className="new__card">
                        <div className='new__img_div me-3'>
                          <img className='new__img' src={e.profile} alt="" />
                        </div>
                        <div>
                          <h6>{e.title}</h6>
                          <div><button className='new__btn'>{((selectedValue === "uz")?"Arxiv":"Архив")}</button><span className='new__data'>{((e.created_at).toString()).slice(0,10)} | {((e.created_at).toString()).slice(11,16)}</span></div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </>
                )
              })
              :
              <div className='text-center'>
                < CircularProgress/>
              </div>
            }
          </div>
        </div>
        <Pagination page={page} onChange={handleChangePage}  count={countPageA} color="primary" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        Hozircha bu sahifa bo`sh
      </TabPanel>
    </Box>
  );
}