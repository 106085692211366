import { Footer } from 'antd/es/layout/layout'
import React from 'react'
import { Navbar } from 'react-bootstrap'
import {BsSearch} from 'react-icons/bs';
import {Link} from "react-router-dom"
import "./read.css"

function Read() {
  return (
    <div>
        <Navbar/>
        <section>
            <div className='container'>
                <div className='JournalSearch'>
                    <div className='retative'>
                        <BsSearch className='readSearchicon'/>
                        <input className='JournalSearchinp' type="text" placeholder='Chop etilgan yili bo`yicha qidiruv' />
                    </div>
                </div>
                <div className='row'>
                    <div className=" col-lg-3 col-md-6 col-sm-12">
                        <Link className='link' to="/all-journal">
                            <div className="readCard">
                            <div className='blackScreen'></div>
                                <img className='w-100 readjournalimg' src="./images/ReadJournal/2021Page.jpg" alt="" />
                                <div><b>Jurnal turi: </b>Teatr</div>
                                <div><b>Chopetigan: </b>2023</div>
                                <div><b>Soni: </b>6</div>
                            </div>
                        </Link>
                    </div>
                    <div className=" col-lg-3 col-md-6 col-sm-12">
                         <div className="readCard">
                         <div className='blackScreen'></div>
                            <img className='w-100 readjournalimg' src="./images/ReadJournal/2021PageG.jpg" alt="" />
                            <div><b>Jurnal turi: </b>Guliston</div>
                            <div><b>Chopetigan: </b>2023</div>
                            <div><b>Soni: </b>6</div>
                         </div>
                    </div>
                    <div className=" col-lg-3 col-md-6 col-sm-12">
                         <div className="readCard">
                         <div className='blackScreen'></div>
                            <img className='w-100 readjournalimg' src="./images/ReadJournal/2021Page.jpg" alt="" />
                            <div><b>Jurnal turi: </b>Teatr</div>
                            <div><b>Chopetigan: </b>2022</div>
                            <div><b>Soni: </b>6</div>
                         </div>
                    </div>
                    <div className=" col-lg-3 col-md-6 col-sm-12">
                         <div className="readCard">
                         <div className='blackScreen'></div>
                            <img className='w-100 readjournalimg' src="./images/ReadJournal/2021PageG.jpg" alt="" />
                            <div><b>Jurnal turi: </b>Guliston</div>
                            <div><b>Chopetigan: </b>2022</div>
                            <div><b>Soni: </b>6</div>
                         </div>
                    </div>


                </div>
            </div>
        </section>
        <Footer/>
    </div>
  )
}

export default Read