import React, { useState, useEffect } from 'react'
import { isDOMComponent } from 'react-dom/test-utils';
import { GrTextAlignRight } from 'react-icons/gr';
import { VscChromeClose } from 'react-icons/vsc';
import { Link } from "react-router-dom"
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../Navbar/navbar.css"
//icon
import { BsSearch } from 'react-icons/bs'
//mui
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//redux import 
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedValue } from '../../redux/Counter';
//import jsons 
import dataUZ from "./navbar_uz.json"
import dataK from "./navbar_k.json"
function Navbar() {
    const [isMobile, setisMobile] = useState(false);
    const [isOpen, setisOpen] = useState(true);
    const [buttonColoruz, setButtonColoruz] = useState();
    const [buttonColork, setButtonColork] = useState();
    //stiky
    const [fix, setFix] = useState(false);
    //dropdown
    const [show1, setShow1] = useState(false);
    //lan redux
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const [data, setdata] = useState({})
    const [age, setAge] = React.useState('uz');

    const handleChangeLan = (event) => {
      setAge(event.target.value);
      dispatch(setSelectedValue(event.target.value));
    };
    useEffect(() => {
        document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
        if (selectedValue === 'uz') {
            setdata({ ...dataUZ })
        } else {
            setdata({ ...dataK })
        }
    }, [selectedValue])
    function setFixed() {
        if (window.scrollY >= 90) {
            setFix(true)
        } else {
            setFix(false)
        }
    }
    window.addEventListener("scroll", setFixed)

    //language start
    
    const dispatch = useDispatch();
    const handleValueSelect01 = (value) => {
        dispatch(setSelectedValue(value)); // Yoki action nomi
      };
    function Mobile_lan_click_uz() {
        const textuz = "uz"
        dispatch(setSelectedValue(textuz));

    }
    const Mobile_lan_click_k = () => {
        const textk = "k"
        dispatch(setSelectedValue(textk));

    };
    const getInitialState = () => {
        const value = "uz";
        return value;
      };

      const [value, setValue] = useState(getInitialState);

      const handleChange = (e) => {
        setValue(e.target.value);
      };
      
      //language end

      //mui start 
      const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
          margin: 1,
          padding: 0,
          transform: 'translateX(6px)',
          '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
              )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
          width: 32,
          height: 32,
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
              '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
          },
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
          borderRadius: 20 / 2,
        },
      }));
      //mui end
      //change color start
      useEffect(() => {
        if(selectedValue == "uz"){
            const grey = "#D2D2D2"
            const white = "white"
            setButtonColoruz(white)
            setButtonColork(grey)
          }
          if(selectedValue == "k"){
            const grey = "#D2D2D2"
            const white = "white"
            setButtonColork(white)
            setButtonColoruz(grey)
          }
      },[selectedValue])

      //change color end
    return (
        <div className={fix ? "All__class__nav" : "All__class__fix"}>
            <div className="container">
                <div className='NavMain'>
                    <div className='NavLogo'>
                        <Link to="/"><img src="/images/logo_002.png" alt="logo" />
                        </Link>
                    </div>
                    <div className='NavItem mt-3'>
                        <ul className='Menu'>
                            <li className='MenuItem'>
                                <NavDropdown
                                    title={
                                        <span className="text-dark main_menu_tit menu__tit MenuHover">{data.nav1}</span>
                                    }
                                    show={show1}
                                    onMouseEnter={() => setShow1(true)}
                                    onMouseLeave={() => setShow1(false)}
                                    onToggle={() => setShow1()}
                                >
                                    <NavDropdown.Item as={Link} to="/management" className='menuhover mt-2 mb-2 ps-4'>{data.nav2}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/guliston" className='menuhover mt-2 mb-2'>{data.nav3}</NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/teatr" className='menuhover mt-2 mb-2' >{data.nav4}</NavDropdown.Item>
                                </NavDropdown>
                            </li>
                            <li className='MenuItem'><Link to={'/yangiliklar'+`?search=Yangiliklar`} state={{ id: 0 }} className='link MenuHover'>{data.nav5}</Link></li>
                            <li className='MenuItem'><Link to={"/yangiliklar"+"?search=Maqolalar"} state={{ id: 1 }} className='link MenuHover'>{data.nav6}</Link></li>
                            <li className='MenuItem'><Link to={"/yangiliklar"+"?search=Jurnaldan"} state={{ id: 2 }} className='link MenuHover'>{data.nav7}</Link></li>
                            <li className='MenuItem'><Link to={"/yangiliklar" +"?search=Arxiv"} state={{ id: 3 }} className='link MenuHover'>{data.nav8}</Link></li>
                            {/* <li className='MenuItem'><Link to="/read-journal" className='link MenuHover'>{data.nav9}</Link></li> */}
                        </ul>
                    </div>
                    <div>
                    {/* <select value={value} onChange={handleChange} className='LanDesktop'>
                        <option value="uz" onClick={handleValueSelect01(value)}>o`zbekcha</option>
                        <option value="k" onClick={handleValueSelect01(value)}>ўзбекча</option>
                    </select> */}
                        <div>
                        <FormControl sx={{ m: 1, minWidth: 100 }}>
                            <Select
                            value={age}
                            onChange={handleChangeLan}
                            defaultValue='k'
                            inputProps={{ 'aria-label': 'Without label' }}
                            >
                            <MenuItem value="uz">o`zbekcha</MenuItem>
                            <MenuItem value="k">ўзбекча</MenuItem>
                            </Select>
                        </FormControl>
                        </div>
                    
                    </div>
                </div>
                <div className='mobile_nav_full_main'>
                    <div className="NavMobile">
                        <div className='MobileLogo'>
                            <Link to="/">
                                <img src="/images/logo_002.png" alt="logo" />
                            </Link>
                        </div>
                        <div className='ToggleMenu'>
                            {
                                (isOpen === true) ? <div onClick={(() => { { setisOpen(false) } { setisMobile(true) } })}><GrTextAlignRight className='icon_toggle me-3' /></div> : <div onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}><VscChromeClose className='text-danger font-weight-bold icon_toggle me-3' /></div>
                            }
                        </div>
                    </div>

                    {(isMobile) == true ?
                        <div className='Last__Mobile__menu'>
                            <ul>
                                <li>
                                    <div className='Mobile_option'>
                                        <div className='Mobile_lan_btn'>
                                        <div > <button className='mobile_lan_uz' value="uz" style={{ backgroundColor: buttonColoruz }} onClick={Mobile_lan_click_uz}>o`z</button></div>
                                        <div><button className='mobile_lan_uz' style={{ backgroundColor: buttonColork }} onClick={Mobile_lan_click_k}>ўз</button> </div>
                                        </div>

                                        <div>
                                        {/* <FormControlLabel
                                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                                        /> */}
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <NavDropdown
                                        title={
                                            <span className="text-dark main_menu_tit  link">{data.nav1}</span>
                                        }
                                        show={show1}
                                        onMouseEnter={() => setShow1(true)}
                                        onMouseLeave={() => setShow1(false)}
                                        onToggle={() => setShow1()}
                                    >
                                        <NavDropdown.Item as={Link} to="/management" className='menuhover ' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>{data.nav2}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/guliston" className='menuhover ' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>{data.nav3}</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="/teatr" className='menuhover' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })} >{data.nav4}</NavDropdown.Item>

                                    </NavDropdown>
                                </li>
                                <li className='Mobile__Item' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>
                                    <span><Link to="/yangiliklar" state={{ id: 0 }} className='link'>{data.nav5}</Link> </span> </li>
                                <li className='Mobile__Item' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>
                                    <span><Link to="/yangiliklar" state={{ id: 1 }} className='link'>{data.nav6}</Link> </span> </li>
                                <li className='Mobile__Item' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>
                                    <span><Link to="/yangiliklar" state={{ id: 2 }} className='link'>{data.nav7}</Link> </span> </li>
                                <li className='Mobile__Item' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>
                                    <span><Link to="/yangiliklar" state={{ id: 3 }} className='link'>{data.nav8}</Link> </span> </li>
                                {/* <li className='Mobile__Item'><span><Link to="/read-journal"  className='link'>{data.nav9}</Link> </span> </li> */}
                                {/* <li className='Mobile__Item' onClick={(() => { { setisOpen(true) } { setisMobile(false) } })}>
                                    <span><Link to="/arxiv" state={{ id: 4 }} className='link'>Jurnalarxiv</Link> </span> </li> */}
                            </ul>
                        </div>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar