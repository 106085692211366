import React, { useEffect, useState } from 'react'
import "./managemen.css"
import { Link } from "react-router-dom"

//redux
import { useSelector } from 'react-redux';

//import json
import dataUZ from "./managements_uz.json"
import dataK from "./managements_k.json"


function Mamage() {
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const [data, setdata] = useState({})




    useEffect(() => {
        console.log(selectedValue)
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (selectedValue === 'uz') {
            setdata({ ...dataUZ })
        } else {
            setdata({ ...dataK })
        }
    }, [selectedValue])
    return (
        <div >
            <div className='managment__menu managment__full'>
                <div className='maneg__back__color'></div>
                <div className='main__text'>
                    <ul className='crumbs'>
                        <li ><Link to="/guliston" className='crumbs-item '>{data.title1}</Link></li>
                        <li>/</li>
                        <li ><Link to="/managment" className='crumbs-item'>{data.title2}</Link></li>
                        <li>/</li>
                        <li className='crumbs-item main-item'>{data.title3}</li>
                    </ul>
                </div>
            </div>
            <div className="container managment__full">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="card m-3 mt-5">
                            <div className="d-flex align-items-center">

                                <div className="col-5">
                                    <img className='managments__imgs managment__full' src="./images/rahbarlar/shahribonu-imomova.jpg" alt="" />
                                </div>
                                <div className="col-7 p-4">
                                    <h4 className="magamend__title">{data.boss1_name}</h4>
                                    <p>{data.boss1_deggre}</p>
                                    <p><b>{data.boss1_work}</b>{data.boss1_word_day}</p>
                                    <p><b>{data.boss1_tel}</b>{data.boss1_number}</p>
                                    <p><b>{data.boss1_email_t}</b>{data.boss1_email}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="card m-3">
                            <div className="d-flex align-items-center">
                                <div className="col-5">
                                    <img className='managments__imgs' src="./images/rahbarlar/hazratqulov-mamatqul1.png" alt="" />
                                </div>
                                <div className="col-7 p-4">
                                    <h4 className="magamend__title">{data.boss2_name} </h4>
                                    <p>{data.boss2_degree}</p>
                                    <p>{data.boss2_dagree_full}</p>
                                    <p><b>{data.boss2_work}</b>{data.boss2_word_day}</p>
                                    <p><b>{data.boss2_tel}</b>{data.boss2_number}</p>
                                    <p><b>{data.boss2_email_t}</b>{data.boss2_email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="card m-3">
                            <div className="d-flex align-items-center">
                                <div className="col-5">
                                    <img className='managments__imgs' src="./images/rahbarlar/uktam_mirzayor.jpg" alt="" />
                                </div>
                                <div className="col-7 p-4">
                                    <h4 className="magamend__title">{data.boss3_name}</h4>
                                    <p>{data.boss3_degree}</p>
                                    <p>{data.boss3_dagree_full}</p>
                                    <p><b>{data.boss3_work}</b>{data.boss3_word_day}</p>
                                    <p><b>{data.boss3_tel}</b>{data.boss3_number}</p>
                                    <p><b>{data.boss3_email_t}</b>{data.boss3_email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mamage