import React,{useState,useEffect} from 'react'
import "./footer.css"
// import rect-icon
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import { BsTelegram } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import { AiTwotonePhone } from 'react-icons/ai';
import { Link } from '@mui/material';
//import json
import dataUZ from "./footer_uz.json"
import dataK from "./footer_k.json"
//redux
import { useSelector } from 'react-redux';
function Footer() {
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const [data, setdata] = useState({})
    function TopBtn(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    useEffect(() => {

        if (selectedValue === 'uz') {
            setdata({ ...dataUZ })
        } else {
            setdata({ ...dataK })
        }
    }, [selectedValue])
  return (
    <div className='full__footer' id="topIconref">
        <div className='footer_main_logo ms-5 ps-5'>
           <img className='footer_logo' src="./images/jurnal__logo.png" alt="" />
           {/* <hr className='liner'/> */}
        </div>  
        <div className="container " >
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <ul className='footermenumain'>
                        <li className='footer_tit'>{data.footer_text1}</li>
                        <li className='footer_item'>{data.footer_text2}</li>
                        <li className='footer_item'>{data.footer_text3}</li>
                        <li className='footer_item'>{data.footer_text4}</li>
                        <li></li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <ul  className='footermenumain'>
                        <li  className='footer_tit'>{data.footer_text5}</li>
                        <li className='footer_item'>{data.footer_text6}</li>
                        <li className='footer_item'>{data.footer_text7}</li>
                        <li className='footer_item'>{data.footer_text8}</li>
                    </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <ul  className='footermenumain'>
                        <li  className='footer_tit'>{data.footer_text9}</li>
                        <li className='footer_item'>{data.footer_text10}</li>
                        <li className='footer_item'><MdOutlineMail className='me-1'/>info@online-journal.uz</li>
                        <li className='footer_item'><AiTwotonePhone className='me-1'/>+998 71 244 41 60</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="">
            <div className=''>
                <ul className='network'>
                   <Link><li className='netwok_item text-primary'><BsFacebook/></li></Link> 
                   <Link><li className='netwok_item text-danger'><BsInstagram/></li></Link> 
                   <Link href='https://t.me/online_journal_uz'><li className='netwok_item text-primary'><BsTelegram/></li></Link>                     
                   <Link  href='https://www.youtube.com/@onlaynjurnalgulistonvateat3190'><li className='netwok_item text-danger'><BsYoutube/></li></Link>
                </ul>
            </div>
        </div>
        <div className='d-flex justify-content-center ms-4'>
            <p>{data.footer_text11}</p>
        </div>
        {/* <div className="topIcon">
        </div> */}
    </div>
  )
}

export default Footer