import React,{useEffect,useState} from 'react'
import LastNews from '../../CommonComponents/Lastnews/LastNews'
import "../Teatr/teatr.css"
import {Link} from "react-router-dom"
//redux
import { useSelector } from 'react-redux';
//lazy
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import json 
import gulistonjson from "./gulistomjson.json"
import dataUZ from "./guliston_uz.json"
import dataK from "./guliston_k.json"
function Guliston() {
    const selectedValue = useSelector(state => state.selection.selectedValue);
    const [data, setdata] = useState({})
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if (selectedValue === 'uz') {
            setdata({ ...dataUZ })
        } else {
            setdata({ ...dataK })
        }
    }, [selectedValue])
  return (
    <div>
    <div className='teatr__main __back_img'>
        <div className="back__color"></div>
        <div className='main__text'>
                    <ul className='crumbs'>
                        <li ><Link to="/guliston" className='crumbs-item '>{data.title1}</Link></li>
                        <li>/</li>
                        <li ><Link to="/managment" className='crumbs-item'>{data.title2}</Link></li>
                        <li>/</li>
                        <li className='crumbs-item main-item'>{data.title3}</li>
                    </ul>
                </div>
        {/* <img className='teatr_main_img' src="./images/big_img.jpg" alt="" /> */}
    </div>
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
                <h3 className='text-center m-4'>{data.main_title}</h3>
                <div className='InformationPage'>
                <LazyLoadImage
                    className='InformationPageImg'
                    alt="AboutWeImg"
                    effect="blur"
                    src="./images/teatr04.jpg" />
                </div>
                <br />
                <p>{data.text1}</p>
                <br />
                <p>{data.text2}</p>
                <br />
                <p>{data.text3}</p>
                <br />
                <p>{data.text4}</p>
                <ul className='teatr__ul'>
                    <li className='teatr__li'>{data.text5}</li>
                    <li className='teatr__li'>{data.text6}</li>
                    <li className='teatr__li'>{data.text7}</li>
                    <li className='teatr__li'>{data.text8}</li>
                </ul>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12m mt-5">
                <LastNews data={gulistonjson}/>
            </div>
        </div>
    </div>
</div>
  )
}

export default Guliston